<template>
  <review-listing title="Application List" :review-line="selectedLine">
    <template v-slot:stats>
      <v-card>
        <v-card-title class="pa-5">
          <v-chip outlined color="pink" class="ma-1 font-weight-bold">
            <v-icon left> mdi-human-female</v-icon>
            Female
            <v-divider vertical class="mx-2" />
            {{ reviewLines.filter((l) => l.Gender === "Female").length }}
          </v-chip>

          <v-chip outlined color="blue" class="ma-1 font-weight-bold">
            <v-icon left> mdi-human-male</v-icon>
            Male
            <v-divider vertical class="mx-2" />
            {{ reviewLines.filter((l) => l.Gender === "Male").length }}
          </v-chip>

          <v-chip outlined color="orange" class="ma-1 font-weight-bold">
            <v-icon left> mdi-account</v-icon>
            {{ EBK ? "No gender assigned" : "Other" }}
            <v-divider vertical class="mx-2" />
            {{ reviewLines.filter((l) => l.Gender === " ").length }}
          </v-chip>
          <v-chip outlined color="green" class="ma-1 font-weight-bold">
            <v-icon left>mdi-human</v-icon>
            Above 35 years
            <v-divider vertical class="mx-2" />
            {{ peopleAbove35.length }}
          </v-chip>

          <v-chip outlined color="red" class="ma-1 font-weight-bold">
            <v-icon left>mdi-human</v-icon>
            Below 35 years
            <v-divider vertical class="mx-2" />
            {{ peopleBelow35.length }}
          </v-chip>

          <v-chip
            v-if="applications.length"
            outlined
            color="primary"
            class="ma-1 font-weight-bold"
            @click="exportToExcel"
          >
            <v-icon left>mdi-download</v-icon>
            export to excel
            <v-divider vertical class="mx-2" />
            {{ applications.length }}
          </v-chip>
          <!-- <v-chip outlined color="green" class="ma-1 font-weight-bold">
            <v-icon left> mdi-human-check</v-icon>
            With Disability
            <v-divider vertical class="mx-2" />
            {{ peopleWithDisabilityCount }}
          </v-chip>

          <v-chip outlined color="red" class="ma-1 font-weight-bold">
            <v-icon left> mdi-human-remove</v-icon>
            Without Disability
            <v-divider vertical class="mx-2" />
            {{ peopleWithoutDisabilityCount }}
          </v-chip> -->
          <v-spacer />
        </v-card-title>
      </v-card>
    </template>

    <template v-slot:list>
      <v-card
        flat
        tile
        :loading="loading"
        :disabled="loading"
        class="transparent"
      >
        <v-toolbar flat elevation="0">
          <v-chip-group
            v-model="selectedType"
            active-class="primary white--text"
            class="mx-4 mt-6"
            column
          >
            <v-chip
              v-for="(type, i) in memberTypes"
              :key="i"
              :value="type.Code"
              :outlined="selectedType !== type.Code"
              filter
              >{{ type.Description }} ({{ type.applicationLength }})</v-chip
            >
          </v-chip-group>
          <v-spacer />

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="reviewerDialog = true"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="info"
                class="mx-1"
              >
                <v-icon left>mdi-share-outline</v-icon>
                Assign
              </v-btn>
            </template>
            <span>Assign Reviewer</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="approveSelected"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="success"
                class="mx-1"
              >
                <v-icon left>mdi-account-check</v-icon>
                {{ approveCaption }}
              </v-btn>
            </template>
            <span>{{ approveCaption }} Selected</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="holdSelected"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="warning"
                class="mx-1"
              >
                <v-icon left>mdi-hand-back-right</v-icon>
                on hold
              </v-btn>
            </template>
            <span>Hold Selected</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="declineSelected"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="error"
                class="mx-1"
              >
                <v-icon left>mdi-close</v-icon>
                decline
              </v-btn>
            </template>
            <span>Reject Selected</span>
          </v-tooltip>
        </v-toolbar>

        <v-data-table
          class="transparent"
          v-model="selected"
          :headers="lineHeaders"
          :items="newReviewLines"
          :search="search"
          :show-select="select"
          item-key="Code"
          :item-class="reviewLineStatus"
          :rows-per-page-options="[5, 15, 50, 100]"
          :items-per-page="100"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="py-10" cols="12" md="6" offset-md="6">
                <v-text-field
                  v-for="(filter, i) in filters"
                  :key="i"
                  v-model="filter.searchText"
                  :placeholder="`Search by ${filter.searchLabel}`"
                  hide-details
                  dense
                >
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn tile text v-bind="attrs" v-on="on">
                          {{ filter.searchLabel }}
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="updateFilter(filter, item)"
                          v-for="(item, index) in filterColumn"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn
                      tile
                      text
                      @click="addFilter"
                      v-if="i === filters.length - 1"
                      :disabled="filters.length >= filterColumn.length"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>

                    <v-btn v-else tile text @click="removeFilter(i)">
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle>
              <v-btn @click="itemSelected(item)" outlined small>
                <v-icon left small> mdi-eye</v-icon>
                Quick View
              </v-btn>
              <v-btn
                v-if="ApplicationStageCanAssigne(item)?.requireAssignment"
                color="info"
                @click="assignToReviewer(item)"
                outlined
                small
              >
                <v-icon color="info" left small>mdi-share-outline</v-icon>
                Assign
              </v-btn>
              <v-btn
                @click="openReviewCard(item)"
                outlined
                color="primary"
                small
              >
                Open
                <v-icon right color="primary" small> mdi-arrow-right</v-icon>
              </v-btn>
              <!-- v-if="&& !item.hasBooklet" -->
              <!-- <v-btn
                @click="uploadItem(item)"
                outlined
                color="green"
                small
                v-if="!item.hasBooklet"
                >Upload
                <v-icon right color="green" small>mdi-upload</v-icon>
              </v-btn> -->
              <!--UNIVERSITY LETTER" -->
              <!-- <v-btn
                @click="sendUniversityLetterTo(item.Code)"
                outlined
                color="green"
                small
                v-if="!item.hasBooklet && !item.universityNotified"
                >Send university letter
                <v-icon right color="secondary" small>mdi-letter</v-icon>
              </v-btn> -->
              <v-btn
                v-if="item.hasBooklet"
                @click="downloadBooklet(item)"
                outlined
                color="green"
                small
              >
                Booklet
                <v-icon right color="green" small> mdi-download </v-icon>
              </v-btn>
              <v-btn
                v-if="item.defer && ApplicationStageCanAssigne(item)?.defer"
                @click="defer(item)"
                outlined
                color="primary"
                small
              >
                defer
                <v-icon right color="primary" small> mdi-close-box</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-card>
      <uploadBookletCard
        :uploadData="uploadData"
        :dialog="uploadDialog"
        @dialogCloser="setUploadDialog"
      />
      <DocumentDialog
        :dialog="documentDialog"
        :applicationCode="applicationCode"
        @emitTosetUploadData="setUploadData"
        @emitSetter="setDocumentDialog"
        @dialogSetter="setUploadDialog"
      />
      <v-dialog v-model="reviewerDialog" scrollable max-width="300px">
        <v-card :loading="assignLoading">
          <v-card-title>Select Reviewer</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-radio-group v-model="selectedReviewer" column>
              <v-radio
                v-for="(r, i) in reviewers"
                :key="i"
                :label="r.names"
                :value="r"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="reviewerDialog = false"> Cancel </v-btn>
            <v-btn
              :disabled="selected.length <= 0"
              color="primary"
              text
              @click="assignReviewer"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </review-listing>
</template>

<script>
import ReviewListing from "../../review/components/reviewListing";
import NullCheckMixin from "@/mixins/nullCheckMixin";
import EventBus from "@/utils/eventBus";
import approvalMixin from "@/modules/review/approvalMixin";
import validationMixin from "@/mixins/ValidationMixin";
import { error, success } from "@/plugins/snack/snack";
import { AuthService } from "@/modules/auth";
import uploadBookletCard from "./uploadBookletCard";
import helperMixin from "../../../mixins/helperMixin";
import DocumentDialog from "./DocumentDialog.vue";
import { eventBus } from "@/utils";
import exportExcel from "@/mixins/exportExcel";

export default {
  name: "applicationList",
  components: { ReviewListing, uploadBookletCard, DocumentDialog },
  mixins: [
    NullCheckMixin,
    approvalMixin,
    validationMixin,
    helperMixin,
    exportExcel,
  ],
  data: function () {
    return {
      search: "",
      select: true,
      dialog: false,
      uploadDialog: false,
      selectedType: "",
      reviewerDialog: false,
      selected: [],
      newReviewLines: [],
      selectedReviewer: "",
      searchValue: "Code",
      searchText: "",
      expanded: [],
      documentDialog: false,
      applicationCode: "",
      lineHeaders: [
        { text: "Application No.", value: "Code" },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Category", value: "AppliedCategory" },
        { text: "Gender", value: "Gender" },
        { text: "Stage", value: "reviewStage" },
        { text: "Status", value: "status" },
        { text: "Reviewer", value: "assignedReviewUser" },
        { text: "Discipline", value: "Discipline" },
        { text: "Action", value: "action" },
      ],
      selectedLine: null,
      processingDialog: true,
      assignLoading: false,
      boardPaperForm: {
        memberCategory: "",
        subscriptionPeriod: "",
      },
      filters: [
        {
          searchValue: "Code",
          searchLabel: "Application No.",
          searchText: "",
        },
      ],
      uploadData: {},
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getReviewers");
      v.$store.dispatch("Application/getStages");
      v.$store.dispatch("Setup/getReviewStages");
      v.$store.dispatch("Application/getApplications");
      v.$store.dispatch("Setup/getMemberTypes");
    });
  },

  mounted() {
    eventBus.$on("excel-download", (param) => {
      this.downloadExcel(param);
    });
  },

  computed: {
    reviewers() {
      return this.$store.getters["Setup/reviewers"];
    },
    memberTypes() {
      const memberTypes = this.$store.getters["Setup/memberType"];
      // const revLines = this.$store.getters["Application/applications"];
      return memberTypes && this.applications
        ? memberTypes
            .map((el) => {
              const applications = this.applications.filter(
                (app) => app.AppliedCategory === el.Code
              );
              if (applications.length > 0) {
                return {
                  ...el,
                  applicationLength: applications.length,
                };
              }
            })
            .filter((el) => el)
        : [];
    },
    reviewStage() {
      const stage = this.stages.filter((stage) => {
        return stage.reviewStageUsers.filter((stageUser) => {
          return stageUser.Reviewer === this.user.code ? stage : {};
        });
      });
      return stage || {};
    },
    applications() {
      console.log(this.$store.getters["Application/applications"]);
      const apps = this.$store.getters["Application/applications"];
      return apps.length !== 0 ? apps : [];
    },
    reviewLines() {
      const revlines =
        this.applications?.length !== 0
          ? this.applications?.filter((app) => {
              let pass = true;

              this.filters.forEach((filter) => {
                pass =
                  app[filter.searchValue]
                    .toLowerCase()
                    .indexOf(filter.searchText.toLowerCase().trim()) > -1 &&
                  pass;
              });
              return pass;
            })
          : [];
      return this.selectedType?.length > 0
        ? revlines.filter((r) => r.AppliedCategory === this.selectedType)
        : revlines;
    },
    processedSelected() {
      return this.selected.filter((s) => !s.loading).length;
    },

    loading: {
      get() {
        return (
          this.processedSelected !== this.selected.length ||
          this.$store.getters["Application/loading"]
        );
      },
      set(val) {
        return this.$store.dispatch("Application/setLoader", val);
      },
    },

    user() {
      return AuthService.user;
    },

    filterColumn() {
      return this.lineHeaders.filter((l) => l.value !== "action");
    },

    stages() {
      return this.$store.getters["Application/stages"];
    },
    peopleAbove35() {
      const currentDate = new Date();
      return this.reviewLines.filter((person) => {
        const dob = new Date(person.DateOfBirth);
        const age = currentDate.getFullYear() - dob.getFullYear();
        return age > 35;
      });
    },

    peopleBelow35() {
      const currentDate = new Date();
      return this.reviewLines.filter((person) => {
        const dob = new Date(person.DateOfBirth);
        const age = currentDate.getFullYear() - dob.getFullYear();
        return age <= 35;
      });
    },
    peopleWithDisabilityCount() {
      return this.reviewLines.filter((person) => person.Disability).length;
    },
    peopleWithoutDisabilityCount() {
      return this.reviewLines.filter((person) => !person.Disability).length;
    },

    // booklet() {
    //   return this.$store.getters["Application/booklet"];
    // },
  },

  methods: {
    ApplicationStageCanAssigne(item) {
      const stage = this.reviewStage.find((stage) => {
        return stage.code === item.reviewStage;
      });
      return stage;
    },
    sendUniversityLetterTo(code) {
      console.log(code);
      this.$store
        .dispatch("Application/sendUniversityLetterTo", {
          applicationNo: code,
        })
        .then((res) => {
          success(res.data);
          this.$store.dispatch("Application/getApplications");
        })
        .catch((err) => {
          error(err.response.data);
        });
    },
    setDocumentDialog(val) {
      this.documentDialog = val;
    },
    itemSelected: function (item) {
      this.selectedLine = item;
      EventBus.$emit("item-selected");
      item.loadProfile();
    },
    openReviewCard: function (item) {
      item.loadProfile();
      this.$router.push({
        name: "Review Card",
        params: {
          Code: item.Code,
        },
        query: {
          type: "application",
        },
      });
    },

    reviewLineStatus: function ({ status }) {
      return {
        New: "blue--text",
        Pending: "grey--text",
        Released: "success--text",
        Declined: "error--text",
        Rejected: "error--text",
        Onhold: "warning--text",
      }[status];
    },

    approveSelected: function () {
      this.$confirm({
        title: `${this.approveCaption} ${this.selected.length} Applications`,
        text: `Are you sure you want ${this.approveCaption}?`,
        input: true,
        label: "Comments",
        onConfirm: (comments) => {
          const items = this.selected.map((el) => {
            el.action = "forward";
            el.comment = comments;
            return el;
          });

          this.$store.dispatch("Application/approveAll", [...items]);

          this.selected = [];
          return;
        },
      });
    },

    holdSelected: function () {
      this.$confirm({
        title: `Hold ${this.selected.length} Applications`,
        text: `Are you sure you want Hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: (comment) => {
          const items = this.selected.map((el) => {
            el.comment = comment;
            el.action = "hold";
            return el;
          });

          this.$store.dispatch("Application/approveAll", [...items]);

          this.selected = [];
        },
      });
    },

    declineSelected: function () {
      this.$confirm({
        title: `Decline ${this.selected.length} Applications`,
        text: `Are you sure you want decline?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          const items = this.selected.map((el) => {
            el.comment = comment;
            el.action = "decline";

            //add  hideFromApplicant
            el.HideFromApplicant = HideFromApplicant ?? false;
            return el;
          });

          this.$store.dispatch("Application/approveAll", [...items]);

          this.selected = [];
        },
      });
    },

    assignReviewer: function () {
      this.assignLoading = true;
      console.log("selected reviewer", this.selectedReviewer);
      this.$store
        .dispatch("Application/assignReviewer", {
          reviewer: this.selectedReviewer,
          applications: this.selected,
        })
        .then((res) => {
          success(res.data.data);
          this.assignLoading = false;
          this.reviewerDialog = false;
          this.selected = [];
          this.$store.dispatch("Application/getApplications");
        })
        .catch((err) => {
          this.assignLoading = false;
          this.reviewerDialog = false;
          error(err.response.data.message);
        });
    },

    assignToReviewer: function (item) {
      this.selected = [item];
      this.reviewerDialog = true;
    },

    addFilter: function () {
      if (this.filters.length < this.filterColumn.length) {
        let currIndex = this.filters.length;

        this.filters.push({
          searchValue: this.filterColumn[currIndex].value,
          searchLabel: this.filterColumn[currIndex].text,
          searchText: "",
        });
      }
    },

    updateFilter: function (filter, column) {
      filter.searchLabel = column.text;
      filter.searchValue = column.value;
      filter.searchText = "";
    },

    removeFilter: function (index) {
      this.filters.splice(index, 1);
    },

    uploadItem(item) {
      this.uploadDialog = true;
      this.uploadData = item;
    },
    setUploadData(code) {
      this.uploadData = {
        ...this.uploadData,
        Code: code,
      };
    },
    setUploadDialog(val) {
      console.log(val);
      this.uploadDialog = val;
    },

    defer: function (item) {
      const data = {
        applicationNo: item.Code,
      };
      this.$store.dispatch("Application/deferApplication", data);
    },
    downloadBooklet(val) {
      this.applicationCode = val.Code;
      this.documentDialog = true;
    },

    exportToExcel() {
      const data = {
        docType: "Application",
        category: "Member",
        stage: "",
        subsriptionPeriod: "",
        gender: "",
      };
      this.$store.dispatch("Application/exportToExcel", data);
    },
  },

  watch: {
    reviewLines: {
      handler: function () {
        if (this.reviewLines.length > 0) {
          if (this.stages.length > 0) {
            this.newReviewLines = [...this.reviewLines];
            this.newReviewLines.forEach((obj) => {
              const res = this.stages.find((o) => o.code === obj.reviewStage);
              console.log(" res ", res);
              obj["handbookMandatory"] = res?.handbookMandatory;
              obj["defer"] = res?.defer;
            });
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
